import React, { useState } from "react";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

const AutoCompelte = ({
  className,
  onClick,
  placeholder,
  options,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div className="flex flex-column AutoCompleteSelect">
      <Autocomplete
        className={className}
        onClick={onClick}
        options={options}
        getOptionLabel={(option) => option?.label}
        value={options?.find((option) => option?.value === value) || null}
        onChange={(event, newValue) =>
          onChange(newValue ? newValue?.value : null)
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        {...rest}
        style={{ textTransform: "capitalize" }}
      />
    </div>
  );
};

export default AutoCompelte;
