import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDKTq54cSbAJ2d7JU63KKOQNhif4y1B8G8",
  authDomain: "chatbot-996b8.firebaseapp.com",
  projectId: "chatbot-996b8",
  storageBucket: "chatbot-996b8.appspot.com",
  messagingSenderId: "210524529217",
  appId: "1:210524529217:web:9bff7275a0a77c7f911cc0"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
