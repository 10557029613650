import Chatbot from "./Component/ChatBot/Chatbot";


function App() {
  return (

<>

<Chatbot/>
</>
     
  );
}

export default App;
